import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormFromKey from "./components/SelectForm/FormFromKey";
import Dash from "./components/DashForm/Dash";
import KeysGen from "./components/KeysGen/KeysGen";
import "./App.css";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import FormReply from "./components/FormReply/FormReply";
import FormSelector from "./components/FormAdmin/FormSelector";
import LogIn from "./components/FormAdmin/LogIn";

// Wrapper component with a fixed width
const PageWrapper = ({ children }) => (
  <div style={{ width: "100%", margin: "0 auto", paddingBottom: "1px" }}>
    {children}
  </div>
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#00a9d2",
    },
  },
});

function App() {
  const [logIn, changeLogIn] = useState(null);

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route
              path="/admin"
              element={
                <PageWrapper>
                  {logIn === null ? (
                    <LogIn changeUserKey={changeLogIn} />
                  ) : (
                    <FormSelector />
                  )}
                </PageWrapper>
              }
            />
            <Route
              path="/forms/*"
              element={
                <PageWrapper>
                  <FormReply />
                </PageWrapper>
              }
            />
            {/* <Route
              path="/forms-debug/*"
              element={
                <PageWrapper>
                  <FormReply />
                </PageWrapper>
              }
            /> */}
            <Route
              path="/keys"
              element={
                <PageWrapper>
                  {logIn === null ? (
                    <LogIn changeUserKey={changeLogIn} />
                  ) : (
                    <KeysGen />
                  )}
                </PageWrapper>
              }
            />
          </Routes>
        </Router>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
