import React, { useEffect, useState, Component } from "react";
import ReactDOM from "react-dom";
import "../../../src/css/Forms.css";
import { makeStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import { Select, MenuItem, Menu } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from '@material-ui/core/FormControl';


import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

const EditQuest = ({ Quest, onQuestEdit, disabled }) => {
  useEffect(() => {
    console.log("Quest:", Quest);
    // getFormInfo();
  }, []);

  function alterOptValue(opIndex, value) {
    var optAux = Quest.opt.slice();
    optAux[opIndex] = value;
    onQuestEdit({ ...Quest, opt: optAux.slice() });
  }

  function deleteOpt(opIndex) {
    var optAux = Quest.opt.slice(opIndex + 1);
    var optAuxResult = Quest.opt.slice(0, opIndex);
    console.log("optAux, optAuxResult", optAux, optAuxResult);
    optAux.map((op) => {
      optAuxResult.push({ ...op, order: optAuxResult.length });
    });
    onQuestEdit({ ...Quest, opt: optAuxResult.slice() });
  }

  function addOpt() {
    var optAux = Quest.opt.slice();
    optAux.push({ id_opt: null, desc: "New Option", order: optAux.length });
    onQuestEdit({ ...Quest, opt: optAux.slice() });
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          {Quest.type === "Info"?
            <TextField
              variant="outlined"
              fullWidth
              multiline
						  minRows={4}
              size="small"
              type="text"
              onChange={(e) => onQuestEdit({ ...Quest, desc: e.target.value })}
              disabled={disabled}
              value={Quest.desc}
              onWheel={(e) => e.target.blur()}
            />
          :
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              onChange={(e) => onQuestEdit({ ...Quest, desc: e.target.value })}
              disabled={disabled}
              value={Quest.desc}
              onWheel={(e) => e.target.blur()}
            />
          }
          
        </Grid>
        <Grid item xs={3}>
        <FormControl size="small" style={{ width: "100%" }}>
          <Select
              value={Quest.type}
              variant="outlined"
              onChange={(e) =>
                onQuestEdit({
                  ...Quest,
                  type: e.target.value,
                  reply: e.target.value === "CheckBox" ? [] : "",
                  opt:
                    e.target.value === "Scale"
                      ? [
                          { id_opt: null, desc: "0", order: 0 },
                          { id_opt: null, desc: "100", order: 1 },
                        ]
                      : [],
                  required: e.target.value === "Info"? 0 : Quest.required
                })
              }
            >
              <MenuItem value="Radio">Multiple choice</MenuItem>
              <MenuItem value="CheckBox">Checkboxes</MenuItem>
              <MenuItem value="Scale">Scale</MenuItem>
              <MenuItem value="Text">Text</MenuItem>
              <MenuItem value="Info">Info</MenuItem>
              <MenuItem value="Number">Number</MenuItem>
              <MenuItem value="Date">Date</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          {Quest.type === "Radio" || Quest.type === "CheckBox" ? (
            <React.Fragment>
              {Quest.opt.map((op, opIndex) => (
                <React.Fragment>
                  <Grid container xs={12} spacing={2}>
                    <Grid
                      item
                      xs={11}
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="text"
                        onChange={(e) =>
                          alterOptValue(opIndex, { ...op, desc: e.target.value })
                        }
                        disabled={disabled}
                        value={op.desc}
                        onWheel={(e) => e.target.blur()}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        style={{ width: "100%", height: "100%" }}
                        onClick={(e) => deleteOpt(opIndex)}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
              <Grid container item xs={12} justifyContent="flex-end" spacing={2}>
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{ width: "100%", height: "100%" }}
                    onClick={(e) => addOpt()}
                  >
                    <AddCircleOutlineOutlinedIcon />
                  </Button>
                </Grid>
              </Grid>

            </React.Fragment>
          ) : null}
          {Quest.type === "Scale" ? (
            <Grid container >
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  onChange={(e) =>
                    alterOptValue(0, { ...Quest.opt[0], desc: e.target.value })
                  }
                  disabled={disabled}
                  value={Quest.opt[0].desc}
                  onWheel={(e) => e.target.blur()}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  onChange={(e) =>
                    alterOptValue(1, { ...Quest.opt[1], desc: e.target.value })
                  }
                  disabled={disabled}
                  value={Quest.opt[1].desc}
                  onWheel={(e) => e.target.blur()}
                />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EditQuest;
