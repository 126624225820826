import React, { useState, useEffect, Component } from "react";
import "../../../src/css/Forms.css";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/Card";
import CardContent from "@material-ui/core/Card";
import CardActions from "@material-ui/core/Card";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormEditor from "./FormEditor.jsx";
import logo from "../../../src/media/images/client-logo.svg"; // relative path to image
import MenuIcon from "@material-ui/icons/Menu";
import Search from "@material-ui/icons/Search";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CFUrl from "../../connectors/CloudFunctions";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function LogIn({ changeUserKey }) {
  const [waitingValidation, changeWaitingValidation] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");

  const [user, changeUser] = useState("");
  const [pass, changePass] = useState("");
  const [logInError, changeLogInError] = useState("");

  useEffect(() => {}, []);

  function validateLogIn() {
    changeWaitingValidation(true);
    const requestContent = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        conteudo: { request: "validateLogIn", user: user, pass: pass },
      }),
    };
    fetch(CFUrl["FormAdmin"], requestContent)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "ok") {
          changeUserKey(res.key);
        } else {
          changeLogInError("Autenticação falhou. Por favor tente novamente.");
        }
        changeWaitingValidation(false);
      });
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={matches ? 8 : 12}>
        <Card style={{ padding: "30px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid
                  item
                  xs={matches ? 6 : 12}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      width: 100,
                      p: 2,
                      textAlign: "left",
                      /* cursor: "pointer", */
                    }}
                    alt="UNA"
                    src={logo}
                    /* onClick={() => {
                      window.location.href = window.location.origin + "/admin"; // Navigate to homepage
                    }} */
                  />
                  <Typography gutterBottom variant="h5" component="div">
                    FORMS
                  </Typography>
                </Grid>

                <Grid item xs={matches ? 6 : 12}>
                  {waitingValidation ? (
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      style={{
                        marginTop: "0px",
                      }}
                    >
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <React.Fragment>
                      <Grid item container justifyContent="center">
                        {logInError.length > 0 ? (
                          <Grid item xs={12} className="custom-alert-section">
                            {logInError}
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          type="text"
                          onChange={(e) => changeUser(e.target.value)}
                          value={user}
                          onWheel={(e) => e.target.blur()}
                          label="Nome de utilizador"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          type="password"
                          onChange={(e) => changePass(e.target.value)}
                          value={pass}
                          onWheel={(e) => e.target.blur()}
                          label="Palavra-chave"
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        alignItems="flex-start"
                        justifyContent="flex-end"
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={(e) => validateLogIn()}
                          disabled={false}
                        >
                          <Box sx={{ fontWeight: "fontWeightBold" }}>
                            Entrar
                          </Box>
                        </Button>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
  // }
}

export default LogIn;
