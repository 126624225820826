import React, { useEffect, useState, Component } from "react";
//import Table from "react-bootstrap/Table";
//import homNum from "./images/homunculo1.jpg";
//import hom from "./images/homunculo2.jpg";
import "./Dash.css";
import "../../../src/css/Forms.css";
import "./App.css";
import { makeStyles, alpha } from "@material-ui/styles";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { grey } from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";
import { Select, MenuItem, Menu } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ArrowDownwardOutlinedIcon from "@material-ui/icons/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import ViewAgendaOutlinedIcon from "@material-ui/icons/ViewAgendaOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { JsonToCsv, useJsonToCsv } from "react-json-csv";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import { ResponsiveVoronoi } from "@nivo/voronoi";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Alert from "@material-ui/lab/Alert";

import EditQuest from "./EditQuest";
import FormReply from "../FormReply/FormReply";
import FormTester from "./FormTester";
import CFUrl from "../../connectors/CloudFunctions";

const FormEditor = ({ id_form }) => {
  const [Page, changePage] = useState("Form");
  const [formObj, changeFormObj] = useState({});
  const [waitingForm, changeWaitingForm] = useState(true);

  const [formTestEval, changeFormTestEval] = useState({});
  const [FormTestSIndex, changeFormTestSIndex] = useState(0);

  useEffect(() => {
    if (id_form === null) {
      changeFormObj({
        id_form: null,
        active: 0,
        desc: "Novo Formulário",
        submit_function: "",
        sections: [
          {
            id_sec: null,
            desc: "Nova Secção",
            order: 0,
            eval_function: "",
            Quests: [],
          },
        ],
      });
      changeWaitingForm(false);
    } else {
      getForm();
    }
  }, []);

  function DeleteForm() {
    const conteudo = {
      request: "deleteForm",
      key: id_form,
    };
    const requestData = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ conteudo: conteudo }),
    };
    fetch(CFUrl["FormAdmin"], requestData)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
      });
  }

  function getForm() {
    const conteudo = {
      id_form: id_form,
      request: "getForm",
    };
    const requestData = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ conteudo: conteudo }),
    };
    fetch(CFUrl["FormAdmin"], requestData)
      .then((res) => res.json())
      .then((res) => {
        changeFormObj(res.formObj);
        changeWaitingForm(false);
      });
  }

  function SaveForm() {
    changeWaitingForm(true);
    const conteudo = {
      request: "saveForm",
      form: formObj,
    };
    const requestData = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ conteudo: conteudo }),
    };
    fetch(CFUrl["FormAdmin"], requestData)
      .then((res) => res.json())
      .then((res) => {
        changeFormObj(res.formObj);
        changeWaitingForm(false);
      });
  }

  function alterFormParam(param, value) {
    var formObjAux = { ...formObj };
    formObjAux[param] = value;
    changeFormObj(formObjAux);
  }

  function alterSection(SIndex, Section) {
    var formObjAux = { ...formObj };
    formObjAux.sections.splice(SIndex, 1, Section);
    changeFormObj(formObjAux);
  }

  function newSection(SIndex) {
    var sectionsAux = formObj.sections.slice(SIndex + 1);
    var sectionsAuxResult = formObj.sections.slice(0, SIndex + 1);
    sectionsAuxResult.push({
      id_sec: null,
      desc: "Nova Secção",
      order: sectionsAuxResult.length,
      eval_function: "",
      Quests: [],
    });
    sectionsAux.map((sec) => {
      sectionsAuxResult.push({ ...sec, order: sectionsAuxResult.length });
    });
    alterFormParam("sections", sectionsAuxResult);
  }

  function alterQuest(SIndex, QIndex, Quest) {
    var formObjAux = { ...formObj };
    formObjAux.sections[SIndex].Quests.splice(QIndex, 1, Quest);
    changeFormObj(formObjAux);
  }

  function newQuest(SIndex) {
    var sectionAux = { ...formObj.sections[SIndex] };
    sectionAux.Quests.push({
      id_quest: null,
      desc: "Nova pergunta",
      type: "Text",
      reply: "",
      order: sectionAux.Quests.length,
      opt: [],
      required: 0,
    });
    alterSection(SIndex, sectionAux);
  }

  function deleteQuest(SIndex, QIndex) {
    var sectionAux = { ...formObj.sections[SIndex] };
    var qstAux = sectionAux.Quests.slice(QIndex + 1);
    var qstAuxResult = sectionAux.Quests.slice(0, QIndex);
    qstAux.map((qst) => {
      qstAuxResult.push({ ...qst, order: qstAuxResult.length });
    });
    sectionAux.Quests = qstAuxResult.slice();
    alterSection(SIndex, sectionAux);
  }

  function moveQuestUp(SIndex, QIndex) {
    var formAux = { ...formObj };
    if (QIndex === 0) {
      var sectionAux1 = { ...formAux.sections[SIndex] };
      var qstAux1 = sectionAux1.Quests[QIndex];
      var questsAux1Result = [];
      sectionAux1.Quests.slice(QIndex + 1).map((qst) => {
        questsAux1Result.push({ ...qst, order: questsAux1Result.length });
      });
      sectionAux1.Quests = questsAux1Result.slice();
      var sectionAux2 = { ...formAux.sections[SIndex - 1] };
      sectionAux2.Quests.push({ ...qstAux1, order: sectionAux2.Quests.length });
      var sectionsResult = formAux.sections.slice();
      sectionsResult[SIndex] = { ...sectionAux1 };
      sectionsResult[SIndex - 1] = { ...sectionAux2 };
      alterFormParam("sections", sectionsResult);
    } else {
      var sectionAux = formAux.sections[SIndex];
      var qstAux1 = sectionAux.Quests[QIndex];
      var qstAux2 = sectionAux.Quests[QIndex - 1];
      sectionAux.Quests[QIndex - 1] = { ...qstAux1, order: QIndex - 1 };
      sectionAux.Quests[QIndex] = { ...qstAux2, order: QIndex };
      console.log("sectionAux", sectionAux);
      alterSection(SIndex, sectionAux);
    }
  }

  function moveQuestDown(SIndex, QIndex) {
    var formAux = { ...formObj };
    if (QIndex === formAux.sections[SIndex].Quests.length - 1) {
      var sectionAux1 = { ...formAux.sections[SIndex] };
      var qstAux1 = { ...sectionAux1.Quests[QIndex] };
      sectionAux1.Quests.splice(QIndex, 1);
      var sectionAux2 = { ...formAux.sections[SIndex + 1] };
      var questsAux2Result = [{ ...qstAux1, order: 0 }];
      sectionAux2.Quests.slice().map((qst) => {
        questsAux2Result.push({ ...qst, order: questsAux2Result.length });
      });
      var sectionsResult = formAux.sections.slice();
      sectionsResult[SIndex] = { ...sectionAux1 };
      sectionsResult[SIndex + 1] = {
        ...sectionAux2,
        Quests: questsAux2Result.slice(),
      };
      alterFormParam("sections", sectionsResult);
    } else {
      var sectionAux = formAux.sections[SIndex];
      var qstAux1 = sectionAux.Quests[QIndex];
      var qstAux2 = sectionAux.Quests[QIndex + 1];
      sectionAux.Quests[QIndex + 1] = { ...qstAux1, order: QIndex + 1 };
      sectionAux.Quests[QIndex] = { ...qstAux2, order: QIndex };
      console.log("sectionAux", sectionAux);
      alterSection(SIndex, sectionAux);
    }
  }

  function alterPage(newPage) {
    if (newPage === "Test") {
      changeFormTestEval({});
      changeFormTestSIndex(0);
    }
    changePage(newPage);
  }

  return (
    <Paper elevation={0} square style={{ marginTop: "10px", width: "100%" }}>
      {waitingForm ? (
        <Grid
          container
          justifyContent="space-between"
          style={{ padding: "10px" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <React.Fragment>
          <ToggleButtonGroup
            value={Page}
            exclusive
            size="small"
            onChange={(event, newPage) => alterPage(newPage)}
            style={{
              justifyContent: "flex-end",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <ToggleButton value={"Form"} style={{ flexGrow: 1 }}>
              FORM
            </ToggleButton>
            <ToggleButton value={"Eval"} style={{ flexGrow: 1 }}>
              EVALUATION
            </ToggleButton>
            <ToggleButton value={"Test"} style={{ flexGrow: 1 }}>
              TEST
            </ToggleButton>
          </ToggleButtonGroup>
          {Page === "Form" ? (
            <Grid
              container
              justifyContent="space-between"
              style={{ padding: "10px" }}
            >
              <Paper className="custom-header">EDIT FORM</Paper>
            </Grid>
          ) : null}
          {Page === "Eval" ? (
            <Grid
              container
              justifyContent="space-between"
              style={{ padding: "10px" }}
            >
              <Paper style={{ width: "100%", padding: "2px" }}>
                FORM EVALUATION
              </Paper>
            </Grid>
          ) : null}
          {Page === "Test" ? (
            <Grid
              container
              justifyContent="space-between"
              style={{ padding: "10px" }}
            >
              <FormTester
                formObj={formObj}
                onReplyChange={(SI, QI, value) =>
                  alterQuest(SI, QI, {
                    ...formObj.sections[SI].Quests[QI],
                    reply: value,
                  })
                }
                formEval={formTestEval}
                onFormEvalChange={(value) => changeFormTestEval(value)}
                SIndex={FormTestSIndex}
                onSIndexChange={(value) => changeFormTestSIndex(value)}
              />
            </Grid>
          ) : null}
          {Page === "Form" ? (
            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              justifyContent="flex-start"
              style={{
                padding: "10px",
              }}
            >
              <Grid container spacing={2} style={{ padding: "10px" }}>
                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) => alterFormParam("desc", e.target.value)}
                    value={formObj.desc}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="caption">Active</Typography>
                  <Switch
                    color="primary"
                    checked={formObj.active == 1}
                    onChange={(e) =>
                      e.target.checked
                        ? alterFormParam("active", 1)
                        : alterFormParam("active", 0)
                    }
                    name="active"
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="caption">Duplo Factor</Typography>
                  <Switch
                    color="primary"
                    checked={formObj.double_factor == 1}
                    onChange={(e) =>
                      e.target.checked
                        ? alterFormParam("double_factor", 1)
                        : alterFormParam("double_factor", 0)
                    }
                    name="double_factor"
                  />
                </Grid>
              </Grid>
              {formObj.sections.map((S, SIndex) => (
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    padding: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    background: "#F2F2F2",
                  }}
                >
                  <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) =>
                        alterSection(SIndex, { ...S, desc: e.target.value })
                      }
                      value={S.desc}
                    />
                  </Grid>
                  {S.Quests.map((Q, QIndex) => (
                    <React.Fragment>
                      <Grid container>
                        <Grid item xs={12}>
                          <EditQuest
                            Quest={Q}
                            onQuestEdit={(e) => alterQuest(SIndex, QIndex, e)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2}>
                          <ToggleButtonGroup
                            exclusive
                            style={{
                              marginTop: "10px",
                              marginBottom: "20px",
                              width: "100%",
                            }}
                            size="small"
                          >
                            <ToggleButton
                              value={"Up"}
                              style={{ flexGrow: 1 }}
                              onClick={(e) => moveQuestUp(SIndex, QIndex)}
                              disabled={QIndex === 0 && SIndex === 0}
                            >
                              <ArrowUpwardOutlinedIcon />
                            </ToggleButton>
                            <ToggleButton
                              value={"Down"}
                              style={{ flexGrow: 1 }}
                              onClick={(e) => moveQuestDown(SIndex, QIndex)}
                              disabled={
                                QIndex === S.Quests.length - 1 &&
                                SIndex === formObj.sections.length - 1
                              }
                            >
                              <ArrowDownwardOutlinedIcon />
                            </ToggleButton>
                            <ToggleButton
                              value={"Delete"}
                              style={{ flexGrow: 1 }}
                              onClick={(e) => deleteQuest(SIndex, QIndex)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={2}>
                          {Q.type === "Info"?
                            null
                            :
                            <React.Fragment>
                              <Typography variant="caption">Required</Typography>
                              <Switch
                                color="primary"
                                justifyContent="flex-end"
                                checked={Q.required == 1}
                                onChange={(e) =>
                                  e.target.checked
                                    ? alterQuest(SIndex, QIndex, {
                                        ...Q,
                                        required: 1,
                                      })
                                    : alterQuest(SIndex, QIndex, {
                                        ...Q,
                                        required: 0,
                                      })
                                }
                                name="required"
                              />
                            </React.Fragment>
                          }
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                  <Grid container item xs={12}>
                    <ToggleButtonGroup
                      exclusive
                      style={{
                        justifyContent: "center",
                        marginTop: "10px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                      size="small"
                    >
                      <ToggleButton
                        value={"Add"}
                        style={{ flexGrow: 1 }}
                        onClick={(e) => newQuest(SIndex)}
                      >
                        <AddCircleOutlineOutlinedIcon />
                      </ToggleButton>
                      <ToggleButton
                        value={"Section"}
                        style={{ flexGrow: 1 }}
                        onClick={(e) => newSection(SIndex)}
                      >
                        <ViewAgendaOutlinedIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              ))}
              <Grid
                container
                justifyContent="flex-end"
                spacing={2}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <Grid item xs={2}>
                  <Box sx={{ fontWeight: "fontWeightBold", height: "100%" }}>
                    <Button
                      variant="contained"
                      size="small"
                      style={{ width: "100%", height: "100%" }}
                      onClick={(e) => console.log(true, e)}
                    >
                      {"Delete Form"}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box sx={{ fontWeight: "fontWeightBold", height: "100%" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      style={{ width: "100%", height: "100%" }}
                      onClick={() => SaveForm()}
                    >
                      {"Save Form"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {Page === "Eval" ? (
            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              justifyContent="flex-start"
              style={{
                padding: "10px",
              }}
            >
              {formObj.sections.map((S, SIndex) => (
                <Grid
                  container
                  item
                  xs={12}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <Grid item xs={3} style={{ paddingLeft: "10px" }}>
                    <Paper style={{ padding: "2px" }}>{S.desc}</Paper>
                  </Grid>
                  <Grid container item xs={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      minRows={4}
                      onChange={(e) =>
                        alterSection(SIndex, {
                          ...S,
                          eval_function: e.target.value,
                        })
                      }
                      value={S.eval_function}
                    />
                  </Grid>
                </Grid>
              ))}
              <Grid
                container
                item
                xs={12}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <Grid item xs={3} style={{ paddingLeft: "10px" }}>
                  <Paper style={{ padding: "2px" }}>{"Submissão"}</Paper>
                </Grid>
                <Grid container item xs={12}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    minRows={4}
                    onChange={(e) =>
                      alterFormParam("submit_function", e.target.value)
                    }
                    value={formObj.submit_function}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                spacing={2}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <Grid item xs={6}>
                  <Box sx={{ fontWeight: "fontWeightBold", height: "100%" }}>
                    <Button
                      variant="contained"
                      size="small"
                      style={{ width: "100%", height: "100%" }}
                      onClick={() => SaveForm()}
                    >
                      {"Save Form"}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ fontWeight: "fontWeightBold", height: "100%" }}>
                    <Button
                      variant="contained"
                      size="small"
                      style={{ width: "100%", height: "100%" }}
                      onClick={(e) => console.log(true, e)}
                    >
                      {"Delete Form"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </React.Fragment>
      )}
    </Paper>
  );
};

export default FormEditor;
