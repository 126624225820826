import React, { useEffect, useState, Component } from "react";
import ReactDOM from "react-dom";
import "../../../src/css/Forms.css";
import { makeStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import PresentQuest from "./PresentQuest";
import logo from "../../../src/media/images/client-logo.svg"; // relative path to image
import CFUrl from "../../connectors/CloudFunctions";

const FormReply = ({ form }) => {
  const [Page, changePage] = useState("Form");

  const UserKey = window.location.pathname.split("/")[2];

  const [DFKey, changeDFKey] = useState(null);

  const [keyNotFound, changeKeyNotFound] = useState(false);

  const [DFFlag, changeDFFlag] = useState(false);

  const [formObj, changeFormObj] = useState();

  const [section, changeSection] = useState();

  const [onLoad, changeOnLoad] = useState(true);

  const [submitFlag, changeSubmitFlag] = useState(false);

  const [submitErrorFlag, changeSubmitErrorFlag] = useState(false);

  const [formParams, changeFormParams] = useState({});

  const [formKeyParams, changeFormKeyParams] = useState({});

  const [requiredErroFlag, changeRequiredErroFlag] = useState(false);

  useEffect(() => {
    console.log("useEffect:", formObj);
    var paramsObject = {};
    for (let [key, value] of new URLSearchParams(
      window.location.search
    ).entries()) {
      paramsObject[key] = value;
    }
    changeFormParams(paramsObject);
    console.log(paramsObject);
    getFormInfo();
  }, []);

  function getFormInfo() {
    changeOnLoad(true);
    console.log("getFormInfo");
    const conteudo = {
      UserKey: UserKey,
      double_factor: DFKey,
      request: "GetFormUserKey",
    };
    const requestData = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ conteudo: conteudo }),
    };
    fetch(CFUrl["FormReply"], requestData)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "ok") {
          changeFormObj(res.formObj);
          changeSection(res.section);
          changeFormKeyParams(res.keyParams)
        } else {
          console.log("keynotfound");
          changeDFFlag(!DFFlag);
          changeKeyNotFound(true);
        }
        changeOnLoad(false);
      });
  }

  function getSecEval() {
    changeOnLoad(true);
    changeRequiredErroFlag(false);

    var paramsList={...formParams, ...formKeyParams}

    const conteudo = {
      section: section,
      UserKey: UserKey,
      Params: paramsList,
    };
    if (formObj["ctl_active"] + "" === 1 + "") {
      conteudo["request"] = "SubmitSec";
    } else {
      conteudo["request"] = "GetNextSec";
    }
    const requestData = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ conteudo: conteudo }),
    };
    fetch(CFUrl["FormReply"], requestData)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "ok") {
          console.log(res);
          changeSection(res.nextSec);
        } else {
          console.log("keynotfound");
          changeKeyNotFound(true);
        }
        changeOnLoad(false);
      });
  }

  function submitForm() {
    changeOnLoad(true);
    changeRequiredErroFlag(false);

    var paramsList={...formParams, ...formKeyParams}

    const conteudo = {
      request: "SubmitForm",
      UserKey: UserKey,
      Params: paramsList,
    };
    const requestData = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ conteudo: conteudo }),
    };
    // fetch(CFUrl["formTestError"], requestData)
    fetch(CFUrl["FormReply"], requestData)
      .then((res) => 
        res.json()
      ).then((res) => {
        if (res.status === "ok") {
          changeSubmitFlag(true);
        } else {
          changeSubmitErrorFlag(true);
          console.log("error");
        }
        changeOnLoad(false);
      })
      .catch(err => {
        console.log(err) 
        changeSubmitErrorFlag(true);
        changeOnLoad(false);
      });
  }

  function alterReply(QIndex, value) {
    console.log("QIndex, value", QIndex, value);
    var sectionAux = { ...section };
    sectionAux.Quests[QIndex].reply = value;
    console.log(sectionAux);
    changeSection(sectionAux);
  }

  function requiredCheker() {
    var cheker = 0;
    if (section !== null) {
      section.Quests.map((Q) => {
        if (Q.required === 1) {
          if (
            Q.type === "Scale" ||
            Q.type === "Text" ||
            Q.type === "Number" ||
            Q.type === "Date" ||
            Q.type === "Radio"
          ) {
            if (Q.reply === "") {
              cheker = 1;
              return cheker;
            }
          }
          if (Q.reply.length === 0) {
            cheker = 1;
            return cheker;
          }
        }
      });
    }
    return cheker;
  }

  if (formObj === undefined) {
    return (
      <React.Fragment>
        {keyNotFound === true ? (
          <Paper
            elevation={0}
            square
            style={{
              marginTop: "10px",
              border: "1px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              justifyContent="space-between"
              style={{
                marginTop: "0px",
              }}
            >
              <Grid container justifyContent="center" spacing={2}>
                {DFFlag ? (
                  <React.Fragment>
                    <Grid
                      item
                      xs={6}
                      alignItems="flex-start"
                      justifyContent="space-between"
                      style={{
                        marginTop: "0px",
                      }}
                    >
                      <TextField
                        variant="filled"
                        size="small"
                        fullWidth
                        onChange={(e) => changeDFKey(e.target.value)}
                        value={DFKey}
                        label={"Chave de acesso"}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={(e) => getFormInfo()}
                      >
                        <Box sx={{ fontWeight: "fontWeightBold" }}>
                          Submeter
                        </Box>
                      </Button>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <Paper>
                    <Box sx={{ fontWeight: "fontWeightBold" }}>
                      {"Formulário não existe ou não se encontra activo".toUpperCase()}
                    </Box>
                  </Paper>
                )}
              </Grid>
            </Grid>
          </Paper>
        ) : null}
      </React.Fragment>
    );
  } else {
    return (
      <Card style={{ padding: "30px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <Box
                  component="img"
                  sx={{
                    width: 100,
                    p: 2,
                    textAlign: "left",
                    /* cursor: "pointer", */
                  }}
                  alt="UNA"
                  src={logo}
                  /* onClick={() => {
                    window.location.href = window.location.origin + "/admin"; // Navigate to homepage
                  }} */
                />
              </Grid>
              <Grid item xs={10} style={{ textAlign: "right" }}>
                <Typography gutterBottom variant="h5" component="div">
                  {formObj.desc.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container xs={12}>
          {Page === "Form" || true ? (
            <React.Fragment>
              {onLoad ? (
                <CircularProgress />
              ) : (
                <React.Fragment>
                  {section === null ? (
                    <React.Fragment>
                      {submitErrorFlag ? (
                        <React.Fragment>
                          <Grid container justifyContent="center">
                            <Grid item xs={8} className="custom-alert-section">
                              Não foi possível submeter o formulário. Por favor
                              tente novamente mais tarde.
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ) : null}

                      {submitFlag ? (
                        <Grid container justifyContent="center">
                          <Grid item xs={8} className="custom-success-section">
                            Questionário submetido com sucesso.
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          justifyContent="center"
                          spacing={2}
                          style={{ marginTop: "20px", marginBottom: "20px" }}
                        >
                          <Grid item xs={2}>
                            <Button
                              color="primary"
                              variant="contained"
                              size="small"
                              onClick={(e) =>
                                requiredCheker() == 1
                                  ? changeRequiredErroFlag(true)
                                  : submitForm()
                              }
                              disabled={false}
                            >
                              <Box sx={{ fontWeight: "fontWeightBold" }}>
                                Submeter
                              </Box>
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Paper className="custom-header-section">
                        <Typography variant="h6">
                          {section.desc.toUpperCase()}
                        </Typography>
                      </Paper>
                      {section.Quests.map((Q, QIndex) => (
                        <Grid container item xs={12}>
                          <PresentQuest
                            Quest={Q}
                            onReply={(e) => alterReply(QIndex, e)}
                            disabled={formObj["ctl_active"] + "" !== 1 + ""}
                            requiredErroFlag={requiredErroFlag}
                          />
                        </Grid>
                      ))}
                      <Grid container spacing={2}>
                        <Grid item xs={12} align="right">
                          <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={(e) =>
                              requiredCheker() == 1
                                ? changeRequiredErroFlag(true)
                                : getSecEval()
                            }
                            disabled={false}
                          >
                            Próximo
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          ) : null}
        </Grid>
      </Card>
    );
  }
};

export default FormReply;
