import React, { useState, useEffect, Component } from "react";
import "../../../src/css/Forms.css";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/Card";
import CardContent from "@material-ui/core/Card";
import CardActions from "@material-ui/core/Card";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormEditor from "./FormEditor.jsx";
import logo from "../../../src/media/images/client-logo.svg"; // relative path to image
import MenuIcon from "@material-ui/icons/Menu";
import Search from "@material-ui/icons/Search";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CFUrl from "../../connectors/CloudFunctions";
import useMediaQuery from '@material-ui/core/useMediaQuery';

function FormSelector() {
  const [formKeys, changeFormKeys] = useState([]);
  const [waitingFormKeys, changeWaitingFormKeys] = useState(true);
  const [SelectForm, changeSelectForm] = useState(0);
  const matches = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    getFormKeysInfo();
  }, []);

  function getFormKeysInfo() {
    const requestTeste = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ conteudo: { request: "getOperationalForms" } }),
    };
    fetch(CFUrl["FormAdmin"], requestTeste)
      .then((res) => res.json())
      .then((res) => {
        changeFormKeys([
          ...res.result,
          // { id_form: 1, desc: "Teste Form 1" },
          // { id_form: 2, desc: "Teste Form 2" },
        ]);
        changeWaitingFormKeys(false);
      });
  }

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [filterMenu, changeFilterMenu] = useState("");

  const handleClickMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  function selectFormMenu(id_form) {
    setMenuAnchor(null);
    changeSelectForm(id_form);
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={matches ? 8: 12}>
        <Card style={{ padding: "30px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item xs={matches ? 6 : 12} style={{ textAlign: "left", display: "flex", alignItems: "center" }}>
                  <Box
                    component="img"
                    sx={{
                      width: 100,
                      p: 2,
                      textAlign: "left",
                      /* cursor: "pointer", */
                    }}
                    alt="UNA"
                    src={logo}
                    /* onClick={() => {
                      window.location.href = window.location.origin + "/admin"; // Navigate to homepage
                    }} */
                  />
                  <Typography gutterBottom variant="h5" component="div">
                    FORMS
                  </Typography>
                </Grid>

                <Grid item xs={matches ? 6 : 12}>
                  {waitingFormKeys ? (
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      style={{
                        marginTop: "0px",
                      }}
                    >
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems="flex-end"
                      justifyContent="flex-end"
                      style={{
                        marginTop: "0px",
                      }}
                    >
                      <Button
                        selected={-1 === SelectForm}
                        onClick={(e) => selectFormMenu(-1)}
                      >
                        Criar novo
                      </Button>

                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => handleClickMenu(e)}
                      >
                        <MenuIcon />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={menuAnchor}
                        keepMounted
                        open={Boolean(menuAnchor)}
                        onClose={(e) => handleCloseMenu()}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        className="filter-menu"
                      >
                        <TextField
                          id="Form_Menu_Filter"
                          key="Form_Menu_Filter"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={(e) => changeFilterMenu(e.target.value)}
                          value={filterMenu}
                          onKeyDown={(e) => e.stopPropagation()}
                          InputProps={{
                            startAdornment: (
                              <Search
                                style={{ color: "#9e9e9e", fontSize: "1.2rem" }}
                              />
                            ),
                          }}
                        />
                        {formKeys.map((f, index) => (
                          <React.Fragment>
                            {f.desc
                              .toUpperCase()
                              .includes(filterMenu.toUpperCase()) ? (
                              <MenuItem
                                style={{ padding: "5px" }}
                                selected={f.id_form === SelectForm}
                                onClick={(e) => selectFormMenu(f.id_form)}
                              >
                                {f.desc}
                              </MenuItem>
                            ) : null}
                          </React.Fragment>
                        ))}
                      </Menu>

                      {/* <Menu
                        id="simple-menu"
                        anchorEl={menuAnchor}
                        keepMounted
                        open={Boolean(menuAnchor)}
                        onClose={(e) => handleCloseMenu()}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {formKeys
                          .filter((fk) => fk.desc.includes(filterMenu))
                          .map((f, index) => (
                            <MenuItem onClick={(e) => selectFormMenu(f.id_form)}>
                              {f.desc}
                            </MenuItem>
                          ))}
                        <MenuItem onClick={(e) => selectFormMenu(-1)}>
                          Criar novo
                        </MenuItem>
                      </Menu> */}
                      {/* {formKeys.map((f, index) => (
                  
                    <Button
                      variant="contained"
                      size="small"
                      style={{ width: "100%", marginBottom: "10px" }}
                      onClick={(e) => changeSelectForm(f.id_form)}
                    >
                      {f.desc}
                    </Button>
                ))}
                
                  <Button
                    variant="contained"
                    size="small"
                    style={{ width: "100%", marginBottom: "10px" }}
                    onClick={(e) => changeSelectForm(-1)}
                  >
                    Criar novo
                  </Button> */}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Paper
              className="shadow"
              elevation={0}
              square
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                width: "100%",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <Grid
                container
                item
                xs={12}
                alignItems="flex-start"
                justifyContent="space-between"
                style={{
                  marginTop: "0px",
                }}
              >
                {formKeys.map((f, index) => (
                  <React.Fragment>
                    {SelectForm === f.id_form ? (
                      <FormEditor key={index} id_form={f.id_form} />
                    ) : null}
                  </React.Fragment>
                ))}
                {SelectForm === -1 ? <FormEditor id_form={null} /> : null}
              </Grid>
            </Paper>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
  // }
}

export default FormSelector;
