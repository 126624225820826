import React, { useEffect, useState, Component } from "react";
import ReactDOM from "react-dom";
import "../../../src/css/Forms.css";
import { makeStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

const PresentQuest = ({ Quest, onReply, disabled, requiredErroFlag }) => {
  useEffect(() => {
    console.log("Quest:", Quest);
    // getFormInfo();
  }, []);

  function checkBoxReply(checked, value) {
    var questAux = Quest.reply.slice();
    if (checked) {
      questAux.push(value + "");
    } else {
      questAux.splice(
        Quest.reply.findIndex((qr) => qr === value + ""),
        1
      );
    }
    onReply(questAux);
  }

  return (
    <React.Fragment>
      <Grid container item xs={12} style={{ margin: "10px" }}>
        {Quest.type === "Info"?
          <Grid container item xs={12} style={{ marginBottom: "5px" }}>
            <Typography variant="body1" style={{ whiteSpace: "pre-line", textAlign: "left" }}>{Quest.desc}</Typography>
          </Grid>
        :
          <Grid container item xs={12} style={{ marginBottom: "5px" }}>
            <Typography variant="body1">{Quest.desc.toUpperCase()}</Typography>
            {requiredErroFlag &&
            Quest.required === 1 &&
            Quest.reply.length === 0 ? (
              <Typography variant="body2" style={{ color: "#FF0000" }}>
                {" *Campo obrigatório"}
              </Typography>
            ) : null}
          </Grid>
        }
        <Grid container item xs={12}>
          {Quest.type === "Number" ? (
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              onChange={(e) => onReply(e.target.value)}
              disabled={disabled}
              value={Quest.reply}
              onWheel={(e) => e.target.blur()}
            />
          ) : null}
          {Quest.type === "Date" ? (
            <TextField
              variant="outlined"
              size="small"
              type="date"
              onChange={(e) => onReply(e.target.value)}
              disabled={disabled}
              value={Quest.reply}
              onWheel={(e) => e.target.blur()}
            />
          ) : null}
          {Quest.type === "Text" ? (
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              onChange={(e) => onReply(e.target.value)}
              disabled={disabled}
              value={Quest.reply}
              onWheel={(e) => e.target.blur()}
            />
          ) : null}
          {Quest.type === "Radio" ? (
            <React.Fragment>
              {Quest.opt.map((op) => (
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <spam>{op.desc}</spam>
                  <Radio
                    color="primary"
                    value={op.order}
                    checked={Quest.reply === op.order + ""}
                    disabled={disabled}
                    onChange={(e) => onReply(e.target.value)}
                  />
                </Grid>
              ))}
            </React.Fragment>
          ) : null}
          {Quest.type === "CheckBox" ? (
            <React.Fragment>
              {Quest.opt.map((op) => (
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <spam>{op.desc}</spam>
                  <Checkbox
                    color="primary"
                    disabled={disabled}
                    value={op.order}
                    checked={Quest.reply.includes(op.order + "")}
                    onChange={(e) => {
                      checkBoxReply(e.target.checked, op.order);
                    }}
                  />
                </Grid>
              ))}
            </React.Fragment>
          ) : null}
          {Quest.type === "Scale" ? (
            <Grid
              container
              item
              xs={12}
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5p",
              }}
            >
              <Slider
                min={parseInt(Quest.opt[0].desc)}
                max={parseInt(Quest.opt[1].desc)}
                marks={[
                  {
                    value: parseInt(Quest.opt[0].desc),
                    label: Quest.opt[0].desc,
                  },
                  {
                    value: parseInt(Quest.opt[1].desc),
                    label: Quest.opt[1].desc,
                  },
                ]}
                disabled={disabled}
                onChange={(e, value) => onReply(value)}
                value={parseInt(Quest.reply)}
                style={{
                  color: "#000000",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                valueLabelDisplay="on"
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PresentQuest;
