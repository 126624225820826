import React, { useEffect, useState, Component } from "react";
import ReactDOM from "react-dom";
import "../../../src/css/Forms.css";
import { makeStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import PresentQuest from "../FormReply/PresentQuest";
import CFUrl from "../../connectors/CloudFunctions";

const FormTester = ({
  formObj,
  onReplyChange,
  formEval,
  onFormEvalChange,
  SIndex,
  onSIndexChange,
}) => {
  const [Page, changePage] = useState("Form");

  const [waitingSecEval, changeWaitingSecEval] = useState(false);

  const [requiredErroFlag, changeRequiredErroFlag] = useState(false);

  useEffect(() => {
    console.log("params FormTester:", formObj, formEval, SIndex);
  }, []);

  function getSecEval(SIndex) {
    changeWaitingSecEval(true);
    changeRequiredErroFlag(false);
    const conteudo = {
      request: "EvalSectionReply",
      section: formObj.sections[SIndex],
      eval: formEval,
    };
    const requestData = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ conteudo: conteudo }),
    };
    fetch(CFUrl["FormAdmin"], requestData)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "ok") {
          var auxFormEval = { ...formEval };
          auxFormEval[SIndex] = res.result;
          onFormEvalChange(auxFormEval);
          onSIndexChange(res.nextSec);
          console.log(res);
        } else {
          console.log("error");
        }
        changeWaitingSecEval(false);
      });
  }

  function getSubmitEval() {
    changeWaitingSecEval(true);
    changeRequiredErroFlag(false);
    const conteudo = {
      request: "EvalSubmit",
      submit_function: formObj.submit_function,
      eval: formEval,
    };
    const requestData = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ conteudo: conteudo }),
    };
    fetch(CFUrl["FormAdmin"], requestData)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "ok") {
          var auxFormEval = { ...formEval };
          auxFormEval["final"] = res.result;
          onFormEvalChange(auxFormEval);
          onSIndexChange("Eval");
          console.log(res);
        } else {
          console.log("error");
        }
        changeWaitingSecEval(false);
      });
  }

  // function alterReply(SIndex, QIndex, value) {
  //   console.log("SIndex, QIndex, value", SIndex, QIndex, value);
  //   var formObjAux = { ...formObj };
  //   formObjAux.sections[SIndex].Quests[QIndex].reply = value;
  //   console.log(formObjAux.sections[SIndex].Quests);
  //   changeFormObj(formObjAux);
  // }

  function requiredCheker(SIndex) {
    var cheker = 0;
    {
      formObj.sections[SIndex].Quests.map((Q) => {
        if (Q.required === 1) {
          if (
            Q.type === "Scale" ||
            Q.type === "Text" ||
            Q.type === "Number" ||
            Q.type === "Date" ||
            Q.type === "Radio"
          ) {
            if (Q.reply === "") {
              cheker = 1;
              return cheker;
            }
          }
          if (Q.reply.length === 0) {
            cheker = 1;
            return cheker;
          }
        }
      });
    }
    return cheker;
  }

  return (
    <Paper
      elevation={0}
      square
      style={{
        marginTop: "10px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
        border: "1px solid #000000",
        borderRadius: "5px",
        overflow: "hidden",
      }}
    >
      <Paper>{formObj.desc.toUpperCase()}</Paper>
      <Grid
        container
        item
        xs={12}
        alignItems="flex-start"
        justifyContent="space-between"
        style={{
          marginTop: "0px",
        }}
      >
        {waitingSecEval ? (
          <CircularProgress />
        ) : (
          <React.Fragment>
            {Page === "Form" || true ? (
              <React.Fragment>
                {SIndex === "Eval" ? (
                  <React.Fragment>{JSON.stringify(formEval)}</React.Fragment>
                ) : (
                  <React.Fragment>
                    {SIndex === "Submit" ||
                    SIndex === formObj.sections.length ? (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={(e) =>
                            requiredCheker(SIndex) == 1
                              ? changeRequiredErroFlag(true)
                              : getSubmitEval()
                          }
                        >
                          <Box sx={{ fontWeight: "fontWeightBold" }}>
                            Submeter
                          </Box>
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Paper
                          style={{
                            padding: "2px",
                            margin: "2px",
                          }}
                        >
                          {formObj.sections[SIndex].desc.toUpperCase()}
                        </Paper>
                        {/* <Grid container item xs={12}>
                          <Grid container item xs={12} style={{ margin: "10px" }}>
                            <Grid container item xs={12} style={{ marginBottom: "5px" }}>
                              <Typography variant="body1">{"O questionário que se segue deve ser preenchido e assinado pelo candidato a pessoa segura, que deve responder a todas as perguntas de forma exata e completa.   As consequências resultantes das omissões ou inexatidões são as previstas no Regime Jurídico do Contrato de Seguro, podendo determinar a anulação do contrato desde o seu início, com perda de prémio a favor do segurador.  Para efeito das respostas a este questionário, o candidato a pessoa segura não deverá considerar situações superadas de risco agravado de saúde, tendo decorrido ininterruptamente 10 anos desde o término do protocolo terapêutico (ou 5 anos, no caso de a patologia superada ter ocorrido antes dos 21 anos de idade), nem situações mitigadas de risco agravado de saúde, tendo decorrido ininterruptamente 2 anos de protocolo terapêutico continuado e eficaz.  De igual modo, não deverá relevar situações superadas de deficiência (com redução de incapacidade igual ou superior a 60% para limiar inferior), tendo decorrido ininterruptamente 10 anos desde o término do protocolo terapêutico, nem situações mitigadas de deficiência (com redução de incapacidade igual ou superior a 60% para limiar inferior), tendo decorrido ininterruptamente 2 anos de protocolo terapêutico continuado e eficaz.  Para o devido enquadramento destas matérias, poderá consultar a legislação aplicável (Lei n.º 46/2006, de 28 de agosto e Lei n.º 75/2021, de 18 de novembro)."}</Typography>
                            </Grid>
                          </Grid>
                        </Grid> */}
                        {formObj.sections[SIndex].Quests.map((Q, QIndex) => (
                          <Grid container item xs={12}>
                            <PresentQuest
                              Quest={Q}
                              onReply={(value) =>
                                onReplyChange(SIndex, QIndex, value)
                              }
                              requiredErroFlag={requiredErroFlag}
                            />
                          </Grid>
                        ))}
                        <Grid
                          container
                          justifyContent="center"
                          spacing={2}
                          style={{ marginTop: "20px", marginBottom: "20px" }}
                        >
                          <Grid item xs={4}>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={(e) =>
                                requiredCheker(SIndex) == 1
                                  ? changeRequiredErroFlag(true)
                                  : getSecEval(SIndex)
                              }
                              disabled={false}
                            >
                              <Box sx={{ fontWeight: "fontWeightBold" }}>
                                Proximo
                              </Box>
                            </Button>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        )}
      </Grid>
    </Paper>
  );
};

export default FormTester;
