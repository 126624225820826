import React, { useState, useEffect, Component } from "react";
import "../../../src/css/Forms.css";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import CFUrl from "../../connectors/CloudFunctions";

function KeysGen() {
  const [formList, changeFormList] = useState([]);
  const [waitingFormList, changeWaitingFormList] = useState(true);
  const [selectedForm, changeSelectedForm] = useState(null);
  const [formKeys, changeFormKeys] = useState({});
  const [waitingFormKeys, changeWaitingFormKeys] = useState(false);

  useEffect(() => {
    getFormList();
  }, []);

  function getFormList() {
    const requestTeste = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ conteudo: { request: "getOperationalForms" } }),
    };
    fetch(CFUrl["FormAdmin"], requestTeste)
      .then((res) => res.json())
      .then((res) => {
        changeFormList([
          ...res.result,
          // { id_form: 1, desc: "Teste Form 1" },
          // { id_form: 2, desc: "Teste Form 2" },
        ]);
        var auxForms = {};
        res.result.map((f) => (auxForms[f.id_form] = []));
        changeFormKeys(auxForms);
        changeWaitingFormList(false);
      });
  }

  function getFormKeys() {
    changeWaitingFormKeys(true);
    const conteudo = {
      Formkey: selectedForm.id_form,
      numberKeys: 1,
    };
    const requestData = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(conteudo),
    };
    fetch(CFUrl["KeyGenerator"], requestData)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "ok") {
          var auxFormKeys = { ...formKeys };
          res.keys.map((key) =>
            auxFormKeys[selectedForm.id_form].push({ ...key })
          );
          changeFormKeys(auxFormKeys);
        }
        changeWaitingFormKeys(false);
      });
  }

  return (
    <Paper
      elevation={0}
      square
      style={{
        marginTop: "10px",
        border: "1px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {waitingFormList ? (
        <Grid
          container
          item
          xs={12}
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{
            marginTop: "0px",
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          alignItems="flex-start"
          justifyContent="space-between"
          style={{
            marginTop: "0px",
          }}
        >
          <Grid container item xs={6}>
            <Autocomplete
              id="size-small-standard"
              size="small"
              options={formList}
              value={selectedForm}
              getOptionLabel={(option) => option.desc}
              onChange={(e, value) => {
                changeSelectedForm(value);
              }}
              style={{
                width: "100%",
              }}
              disabled={waitingFormKeys}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid>
          {selectedForm !== null ? (
            <Grid container item xs={3}>
              <Button
                variant="contained"
                size="small"
                style={{ width: "100%", height: "100%" }}
                onClick={(e) => getFormKeys()}
                disabled={waitingFormKeys}
              >
                <Box sx={{ fontWeight: "fontWeightBold" }}>Obter chaves</Box>
              </Button>
            </Grid>
          ) : null}
        </Grid>
      )}
      {selectedForm !== null && formKeys[selectedForm.id_form].length > 0 ? (
        <Grid
          container
          item
          xs={12}
          alignItems="flex-start"
          justifyContent="space-between"
          style={{
            marginTop: "0px",
          }}
        >
          <Grid container item xs={6}>
            URL key
          </Grid>
          <Grid container item xs={1} />
          <Grid container item xs={5}>
            Chave de acesso
          </Grid>
          {formKeys[selectedForm.id_form].map((fk) => (
            <React.Fragment>
              <Grid container item xs={6} style={{ overflowWrap: "anywhere" }}>
                <Link
                  href={
                    window.location.href.replace("keys", "Forms") +
                    "/" +
                    fk.url_key
                  }
                  underline="hover"
                  target="_blank"
                >
                  {window.location.href.replace("keys", "Forms") +
                    "/" +
                    fk.url_key}
                </Link>
              </Grid>
              <Grid container item xs={1} />
              <Grid container item xs={5} style={{ overflowWrap: "anywhere" }}>
                {fk.user_key}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      ) : null}
    </Paper>
  );
  // }
}

export default KeysGen;
