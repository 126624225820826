const CFUrl = {
  FormAdmin: "https://europe-west3-rift-una.cloudfunctions.net/FormAdmin",
  FormReply: "https://europe-west3-rift-una.cloudfunctions.net/FormReply",
  KeyGenerator: "https://europe-west3-rift-una.cloudfunctions.net/KeyGenerator",
  formRift: "https://europe-west3-rift-una.cloudfunctions.net/formRift",
  formTestError: "https://europe-west3-rift-una.cloudfunctions.net/ErroTest"
};

export default CFUrl;

// una: {
//     FormAdmin: "https://europe-west3-rift-una.cloudfunctions.net/FormAdmin",
//     FormReply: "https://europe-west3-rift-una.cloudfunctions.net/FormReply",
//     KeyGenerator: "https://europe-west3-rift-una.cloudfunctions.net/KeyGenerator",
//     formRift: "https://europe-west3-rift-una.cloudfunctions.net/formRift",
//   };

// quest_dev: {
//     FormAdmin: "https://europe-west3-quest-394115.cloudfunctions.net/FormAdmin",
//     FormReply: "https://europe-west3-quest-394115.cloudfunctions.net/FormReply",
//     KeyGenerator: "https://europe-west3-quest-394115.cloudfunctions.net/KeyGenerator",
//     formRift: "https://europe-west3-quest-394115.cloudfunctions.net/formRift",
//   };
